<template>
  <div class="main-page"
    :class="{loaded: isLoad}"
  >
    <div class="main-page-decore"></div>
    <x-header/>
    <div id="content" class="main-content">
      <slot />
    </div>
    <x-footer/>

    <common-sprites/>
    <div id="gallery"></div>
  </div>
</template>

<script setup lang="ts">
import XFooter from "~/components/layout/XFooter.vue";
import XHeader from "~/components/layout/XHeader.vue";
import CommonSprites from "~/components/CommonSprites.vue";
import {nextTick, onMounted, ref, watch} from "vue";
import {useRoute} from "#app";

const $route = useRoute();

const isLoad = ref(false);
onMounted(() => isLoad.value = true);
watch(() => $route.path, () => {
  isLoad.value = false;
  nextTick(() => isLoad.value = true)
})

function syncPoints() {
  document.body.style.setProperty("--vw", `${(window.innerWidth / 100).toFixed(2)}px`);
  document.body.style.setProperty("--vh", `${(window.innerHeight / 100).toFixed(2)}px`);
}
onMounted(() => {
  syncPoints();
  window.addEventListener("resize",syncPoints);
})
onUnmounted(() => {
  window.removeEventListener("resize",syncPoints);
})
</script>
