<script setup lang="ts">
import {MAIN_NAV, MAIN_NAV_BTN} from "@/configs/main-nav";
import {CONTACTS} from "~/configs/contacts";
const showNav = ref(false);
</script>

<template>
  <header class="main-header" itemscope="itemscope" itemtype="https://schema.org/Organization">
    <div class="container main-header-container"
         :class="{ '--open': showNav }"
    >
      <a class="main-header__logo" href="https://xsquare.ru/" target="_blank" aria-label="Logo. Go to the homepage" itemprop="url">
        <meta itemprop="logo" content="/logo.svg"/>
        <meta itemprop="name" content="Хи-квадрат"/>
        <meta itemprop="address" :content="CONTACTS.address.replace(/<[^>]*>?/gm, '')"/>
        <meta itemprop="telephone" :content="CONTACTS.phone"/>
      </a>

      <nav
        itemscope itemtype="https://schema.org/SiteNavigationElement"
        class="main-header__nav-wrapper"
        :class="{ '--open': showNav }"
      >
        <meta itemprop="name" content="Навигационное Меню">
        <ul class="main-header__nav">
          <li
            v-for="(navItem, index) in MAIN_NAV"
            :key="`main_nav-${index}`"
          >
            <nuxt-link
              itemprop="url"
              @click="showNav = false"
              class="link white"
              :title="navItem.title"
              :target="navItem.target"
              :to="navItem.href">
              <span itemprop="name" style="display: contents">{{ navItem.title }}</span>
            </nuxt-link>
          </li>
        </ul>
      </nav>

      <div class="main-header__right">
        <a v-if="!!MAIN_NAV_BTN?.href" :href="MAIN_NAV_BTN.href" :target="MAIN_NAV_BTN.target" class="header-red-btn">{{ MAIN_NAV_BTN.title }}</a>
      </div>

      <a href="#"
         class="main-header__burger"
         :class="{ '--open': showNav }"
         @click.prevent="showNav = !showNav"
         aria-label="Open/hide menu"
      >
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path stroke="currentcolor" class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
          <path stroke="currentcolor" class="line line2" d="M 20,50 H 80" />
          <path stroke="currentcolor" class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
        </svg>
      </a>
    </div>
  </header>
</template>

<style scoped lang="scss">

</style>
