const ROOT = "https://xsquare.ru"

export const MAIN_NAV = [
    {
        title: "Платформа",
        href: `${ROOT}`,
        target: "_self"
    },
    {
        title: "Готовые решения",
        href: `${ROOT}/solutions/`,
        target: "_self"
    },
    {
        title: "Купить",
        href: `${ROOT}/buy/`,
        target: "_self"
    },
    {
        title: "Документация",
        href: `${ROOT}/docs/`,
        target: "_self"
    },
    {
        title: "Скачать",
        href: `${ROOT}/download/`,
        target: "_self"
    },
    {
        title: "О нас",
        href: `${ROOT}/about/`,
        target: "_self"
    }
]

export const MAIN_NAV_BTN = {
    title: "Замена ORACLE",
    href: "https://migration.xsquare.ru",
    target: "_blank"
}
